
    import { defineComponent, ref, onMounted, nextTick, inject, watch, onUnmounted, PropType } from "vue";
    import ClassIntro from "@/components/common/ClassIntro.vue";
    import Loading from "@/components/UI/Loading";
    import MenuTab from "@/components/common/MenuTab.vue";
    import ClassSchCard from "@/components/common/ClassSchCard.vue";
    import ClassComment from "@/components/common/ClassComment.vue";
    import ClassFooter from "@/components/common/ClassFooter.vue";

    import api from "@/api/axios";
    import { useRoute, useRouter } from "vue-router";
    import dateFormat, { timeFormat } from "@/utils/dateFormat";
    import asyncLoadJs from "@/hooks/useAsyncLoadJs";
    asyncLoadJs("//imgcache.qq.com/open/qcloud/video/tcplayer/lib/hls.min.0.8.8.js", "VideoDetail").then(() => {
        asyncLoadJs("//imgcache.qq.com/open/qcloud/video/tcplayer/tcplayer.min.js", "VideoDetail")
    })
    import vTCPlayer from "@/hooks/useTcplayer";
    import axios from "@/api/axios";
    import qs from "qs";
    import Toast from "@/components/UI/Toast";
    import wx from "weixin-sdk-js";
    export default defineComponent({
        name: "LiveDetail",
        components: {
            ClassIntro,
            MenuTab,
            ClassSchCard,
            ClassComment,
            ClassFooter,
            Loading
        },
        setup() {
            const shareTitle = ref('')
            const route = useRoute();
            const id = ref(route.query.id);
            const router = useRouter();
            const intro = ref(null);
            const detail = ref({
                fileId: ""
            });
            const RecList = ref<object>([]);
            const courseChapterList = ref<object>([]);
            //  切换列表菜单
            const menu = ref(["课程表", "回放目录(0)", "评论(0)"]);

            const itemBool = ref(0);
            function selectMenu(item: string) {
                console.log(item);
                if (item == "课程表") {
                    itemBool.value = 0;
                }
                if (item.indexOf("回放目录") != -1) {
                    itemBool.value = 1;
                }
                if (item.indexOf("评论") != -1) {
                    itemBool.value = 2;
                }
                console.log(itemBool.value);
            }

            function contrast(startDate: string) {
                return new Date().getTime() - new Date(timeFormat(startDate)).getTime() <
                0
                    ? true
                    : false;
            }

            //评论
            const listComment = ref<object>([]);
            const fileId = ref("");
            const uid = ref()
            let Videoplayer: any = null;
            const loadOption = {
                text: "加载中...",
                color: "#df0024",
                textColor: "#df0024",
            };
            const videoFileid = ref(true);

            function getRenderData(id: any) {
                intro.value = null;
                videoFileid.value = true;
                api
                    .get("/M/Discover/VideoDetail/" + id)
                    .then(res => {
                        const data = res.data.obj;
                        intro.value = data.course;
                        detail.value = data;
                        courseChapterList.value = [{...data.course, courseDes: data.course.courseDescription, teacherId: data.course.user.id, ...data.course.user, userName: data.course.user.realName || data.course.user.nickName}];
                        RecList.value = data.VideoList;
                        fileId.value = data.fileId;
                        shareTitle.value = data.course.courseName
                        document.title = data.course.courseName + '-北纳生物'
                        route.meta.title = data.course.courseName
                        route.meta.content.description = data.course.courseDescription;
                        nextTick(() => {
                            Videoplayer = vTCPlayer("video", data.fileId);
                        });
                        // uid.value = data.currentUser && data.currentUser.id;
                        uid.value = data.myuserid;
                        menu.value[1] = "回放目录(" + data.VideoList.length + ")";
                        // menu.value[1] = "回放目录(" + data.VideoList.length + ")";
                        // menu.value[2] = "评论(" + data.listComment.length + ")";
                        // listComment.value = data.listComment;
                        // fileId.value = data.fileId;
                        // nextTick(() => {
                        //     Videoplayer = vTCPlayer("video", data.fileId);
                        // });
                    })
                    .catch(err => {
                        console.log(err);
                    });
            }

            function getNext(id: any){
                itemBool.value = 0;
                api
                    .get("/M/Discover/VideoDetailRelation/" + id)
                    .then(res => {
                        const data = res.data.obj;
                        menu.value[2] = "评论(" + data.listComment.length + ")";
                        listComment.value = data.listComment;
                    })
                    .catch(err => {
                        console.log(err);
                    });
            }

            getRenderData(id.value)
            getNext(id.value);
            const playText = ref("播放");

            function handlePlay(){
                if(Videoplayer.paused()){
                    playText.value = "暂停"
                    Videoplayer.play()
                }else{
                    playText.value = "播放"
                    Videoplayer.pause()
                }
            }

            const comment = ref("");
            const user = inject("userInfo") as object;
            function toLogin() {
                router.push({
                    path: "/login",
                    query: {
                        redirect: router.currentRoute.value.fullPath
                    }
                });
            }

            function subComment() {
                if (!comment.value) {
                    Toast({
                        type: "warning",
                        title: "请先输入评论再提交哦~",
                    });
                    return false;
                }
                const data = qs.stringify({
                    id: id.value,
                    content: comment.value,
                    type: 1
                });
                axios
                    .post("/M/Server/DoCourseComment", data)
                    .then((res) => {
                        if (res.data.success) {
                            Toast({
                                type: "success",
                                title: res.data.msg,
                            });
                            (listComment.value as object[]).unshift({
                                ...res.data.obj,
                                isAlreadyZan: 0,
                                user: {
                                    ...user,
                                    userImg: (user as any).userImg,
                                },
                            });
                            menu.value[2] = "评论(" + (listComment.value as object[]).length + ")";
                            comment.value = "";
                        } else {
                            Toast({
                                type: "error",
                                title: res.data.msg,
                            });
                            router.push({name: "Login"})
                        }
                    })
                    .catch((err) => {

                        const message = err.message ? err.message : "未知错误，请联系管理员";
                        Toast({
                            type: "error",
                            title: message,
                        });
                    });
            }
            watch(
                () => {
                    return shareTitle.value
                },
                (to, from) => {
                    const isweixin = ref(false);
                    const ua = navigator.userAgent.toLowerCase();
                    const reg = /MicroMessenger/i;
                    isweixin.value = reg.test(ua);
                    if (isweixin.value) {
                        nextTick(() => {
                            // const imgUrl = "http://shijiaoke.oss-cn-beijing.aliyuncs.com/Yunan/logo20200923.svg";
                            const link = window.location.href;
                            const title = document.title;
                            const desc = (document.querySelectorAll(
                                "meta[name=description]"
                            )[0] as any).content;
                            console.log(desc);

                            const formData = new FormData();
                            formData.append("url", link);
                            axios.post("/M/Server/getweixin", formData).then((res: any) => {
                                const data = res.data;
                                wx.config({
                                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                                    appId: data.appid, // 必填，公众号的唯一标识
                                    timestamp: parseInt(data.timestamp), // 必填，生成签名的时间戳
                                    nonceStr: data.nonceStr, // 必填，生成签名的随机串
                                    signature: data.str, // 必填，签名
                                    jsApiList: [
                                        "checkJsApi",
                                        "updateAppMessageShareData",
                                        "updateTimelineShareData",
                                    ], // 必填，需要使用的JS接口列表
                                });
                                wx.ready(function () {
                                    //需在用户可能点击分享按钮前就先调用

                                    wx.updateAppMessageShareData({
                                        title: to+'-北纳生物', // 分享标题
                                        desc: route.meta.content.description, // 分享描述
                                        link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                                        imgUrl: "https://beinabncc.oss-cn-beijing.aliyuncs.com/News/72bd4bed20c9be16bbe779d66699e17c.jpg", // 分享图标
                                        success: function () {
                                            // 设置成功
                                        },
                                    });
                                    wx.updateTimelineShareData({
                                        title: to+'-北纳生物', // 分享标题
                                        desc: route.meta.content.description, // 分享描述
                                        link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                                        imgUrl: "https://beinabncc.oss-cn-beijing.aliyuncs.com/News/72bd4bed20c9be16bbe779d66699e17c.jpg", // 分享图标
                                        success: function () {
                                            // 设置成功
                                        },
                                    });
                                });
                            });
                        });
                    }
                }
            )
            onUnmounted(() => {
                if (Videoplayer) {
                    Videoplayer.dispose()
                }
                videoFileid.value = false;
            })


            watch(
                [
                    () => {
                        return route.query;
                    },
                    () => {
                        return route.params;
                    },
                ],
                (to, from) => {
                    // 避免登录时触发页面刷新
                    if (to[0].id && !from[1].valid && itemBool.value!=2) {
                        id.value = to[0].id;
                        if (Videoplayer) {
                            Videoplayer.dispose()
                        }
                        videoFileid.value = false;
                        getRenderData(to[0].id as string);
                        getNext(to[0].id as string)
                    }
                }
            );
            if(localStorage.getItem('WX')){
                setTimeout(function () {
                    location.reload();
                },1000)
            }
            localStorage.removeItem('WX')


            return {
                id,
                toLogin,
                intro,
                menu,
                selectMenu,
                itemBool,
                listComment,
                detail,
                courseChapterList,
                dateFormat,
                RecList,
                contrast,
                timeFormat,
                handlePlay,
                playText,
                comment,
                subComment,
                loadOption,
                getRenderData,
                uid,
                videoFileid
            };
        }
    });
